import { PageSEO } from '@/components/SEO'
import siteMetadata from '@/data/siteMetadata'
import { useState, useRef, useEffect } from 'react'
import SectionContainer from '@/components/SectionContainer'
import animerdleList from '@/data/animerdle_list.json'
import gameSchedule from '@/data/gameSchedule'
import { useTheme } from 'next-themes'
import { getDayOfTheYear } from 'utils/dateHelper'
import { SHA1 } from 'utils/encryption'
import Image from 'next/image'
import SkipBar from '@/components/guess-bars/SkipBar'
import FailedBar from '@/components/guess-bars/FailedBar'
import CurrentBar from '@/components/guess-bars/CurrentBar'
import dynamic from 'next/dynamic'
import mailgo from 'mailgo'

const DynamicPlayController = dynamic(() => import('@/components/PlayController'))

export default function Home({ totalStats }) {
  const animeList = animerdleList

  const [todayAnime, setTodayAnime] = useState(null)
  const [soundUrl, setSoundUrl] = useState('')

  const updateTodayAnime = () => {
    let schedule = gameSchedule[getDayOfTheYear()]
    if (gameSchedule[getDayOfTheYear()] == undefined) {
      const scheduleLength = parseInt(Object.keys(gameSchedule).length)
      const keyIndex = getDayOfTheYear() % scheduleLength // this will be null
      const key = Object.keys(gameSchedule)[keyIndex]
      schedule = gameSchedule[key]
    }

    let additionals = ''
    if (schedule.id != undefined) {
      additionals = `_audio${schedule.audioNum}`
      schedule = schedule.id
    }

    const _todayAnime = animeList.find((x) => x.id == schedule)
    const audioName = `${_todayAnime.id.toString()}`

    setTodayAnime(_todayAnime)
    setSoundUrl(`static/assets/anime/${SHA1(audioName)}${additionals}.mp3`)
  }

  const setStatsInLocalStorage = (_currentGameStats) => {
    if (typeof window !== 'undefined') {
      let stats = localStorage.getItem('stats')
      if (stats != null) {
        stats = JSON.parse(stats)
        const s = stats.find((x) => x.day == getDayOfTheYear())
        if (s != null) {
          s.actions = _currentGameStats.actions
          localStorage.setItem('stats', JSON.stringify(stats))
        } else {
          stats.push(_currentGameStats)
          localStorage.setItem('stats', JSON.stringify(stats))
        }
      } else {
        localStorage.setItem('stats', JSON.stringify([_currentGameStats]))
      }
    }
  }

  const updateRanking = (stats) => {
    const roundsWon = stats.filter((x) => x.actions.find((i) => i.win))

    totalStats.rounds = [...Array(6)].fill(0)
    let avgRoundWin = 0
    for (let i = 0; i < stats.length; i++) {
      for (let j = 0; j < stats[i].actions.length; j++) {
        if (stats[i].actions[j].win) {
          totalStats.rounds[j] += 1
          avgRoundWin += stats[i].actions[j].attemptNum + 1
        }
      }
    }
    if (avgRoundWin > 0) {
      avgRoundWin /= roundsWon.length
      totalStats.avgRoundWin = Math.round(avgRoundWin * 10) / 10
    }
    totalStats.played = stats.length
    totalStats.won = roundsWon.length
    totalStats.avgRoundWin = Math.round(avgRoundWin * 10) / 10
  }

  const [currentGameStats, setCurrentGameStats] = useState({
    day: getDayOfTheYear(),
    malId: gameSchedule[getDayOfTheYear()],
    actions: [],
  })

  const resetCurrentGameStats = () => {
    setCurrentGameStats({
      day: getDayOfTheYear(),
      malId: gameSchedule[getDayOfTheYear()],
      actions: [],
    })
  }

  const [currentAttemptNum, setCurrentAttemptNum] = useState(0)
  const [action, setAction] = useState([...Array('', '', '', '', '', '')])
  const [submittedAnime, setSubmittedAnime] = useState([])

  for (let i = 0; i < currentGameStats.actions.length; i++) {
    if (currentGameStats.actions[i].skip) {
      action[i] = 'skip'
    }
    if (currentGameStats.actions[i].failedGuess) {
      const anime = animeList.find((x) => x.id == currentGameStats.actions[i].animeId)
      submittedAnime[i] = anime.title
      action[i] = 'failedguess'
    }
    if (currentGameStats.actions[i].win) {
      const anime = animeList.find((x) => x.id == currentGameStats.actions[i].animeId)
      submittedAnime[i] = anime.title
      action[i] = 'correctguess'
    }
  }

  const [isDataReady, setIsDataReady] = useState(false)

  const updateStatsFromLocalStorage = () => {
    if (typeof window !== 'undefined') {
      let stats = localStorage.getItem('stats')
      if (stats != null) {
        stats = JSON.parse(stats)
        const s = stats.find((x) => x.day == getDayOfTheYear())
        updateRanking(stats)

        if (s != null) {
          setCurrentGameStats(s)
          setCurrentAttemptNum(s.actions.length)
          const hasWon = s.actions.find((x) => x.win)
          if (hasWon) {
            setFinishedGameState(true)
            activateTimerToNextDay()
            setWin(true)
          } else if (s.actions.length == 6) {
            setFinishedGameState(true)
            activateTimerToNextDay()
          }
        }
      }

      let firstTime = localStorage.getItem('firstTime')
      if (firstTime == null) {
        firstTime = false
        localStorage.setItem('firstTime', firstTime)
        // setHowToPlayModal(true)  // Todo fix this
      }

      setIsDataReady(true)
    }
  }

  const mailgoConfig = {
    dark: true,
  }

  useEffect(() => {
    updateStatsFromLocalStorage()
    updateTodayAnime()
    mailgo(mailgoConfig)
  }, [])

  const [win, setWin] = useState(false)
  const [finishedGame, setFinishedGame] = useState(false)
  const finishedGameStateRef = useRef(finishedGame)
  const setFinishedGameState = (data) => {
    finishedGameStateRef.current = data
    setFinishedGame(data)
  }

  const [opaqueExceptPlay, setOpaqueExceptPlay] = useState(false)

  const theme = useTheme()

  const resetToInitialState = () => {
    const temp = [...Array('', '', '', '', '', '')]
    setAction(temp)
    setCurrentAttemptNum(0)
    setSubmittedAnime([])
    resetCurrentGameStats()
    setFinishedGameState(false)
  }

  const onSubmit = (anime) => {
    if (todayAnime.id == anime.id) {
      // player won, show him finishedGame screen
      const temp = action
      temp[currentAttemptNum] = 'correctguess'
      setAction(temp)
      setFinishedGameState(true)
      setWin(true)

      updateStatsFromLocalStorage()
      currentGameStats.actions[currentAttemptNum] = {
        attemptNum: currentAttemptNum,
        win: true,
        skip: false,
        failedGuess: false,
        animeId: anime.id,
      }
      setStatsInLocalStorage(currentGameStats)
      activateTimerToNextDay()

      // update ranking
      updateStatsFromLocalStorage()
    } else {
      let temp = submittedAnime
      temp[currentAttemptNum] = anime.title
      setSubmittedAnime(temp)

      temp = action
      temp[currentAttemptNum] = 'failedguess'
      setAction(temp)

      updateAttempt()

      updateStatsFromLocalStorage()
      currentGameStats.actions[currentAttemptNum] = {
        attemptNum: currentAttemptNum,
        win: false,
        skip: false,
        failedGuess: true,
        animeId: anime.id,
      }
      setCurrentGameStats(currentGameStats)
      setStatsInLocalStorage(currentGameStats)

      if (currentAttemptNum == 5) {
        // update ranking
        updateStatsFromLocalStorage()
        setFinishedGameState(true)
      }
    }
  }

  const onSkip = () => {
    let temp = action
    temp[currentAttemptNum] = 'skip'
    setAction(temp)
    updateAttempt()

    updateStatsFromLocalStorage()
    currentGameStats.actions[currentAttemptNum] = {
      attemptNum: currentAttemptNum,
      win: false,
      skip: true,
      failedGuess: false,
      animeId: null,
    }
    setCurrentGameStats(currentGameStats)
    setStatsInLocalStorage(currentGameStats)

    if (currentAttemptNum == 5) {
      // update ranking
      updateStatsFromLocalStorage()
      setFinishedGameState(true)
    }
  }

  // time until next animerdle
  const [hoursUntil, setHoursUntil] = useState(0)
  const [minutesUntil, setMinutesUntil] = useState(0)
  const [secondsUntil, setSecondsUntil] = useState(0)

  let tomorrow = new Date()
  tomorrow.setHours(24, 0, 0, 0)
  let countDownDate = tomorrow.getTime()

  const resetClock = () => {
    const today = new Date()
    tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(24, 0, 0, 0)
    countDownDate = tomorrow.getTime()
  }

  const activateTimerToNextDay = () => {
    const x = setInterval(function () {
      // Get today's date and time
      const now = new Date().getTime()

      // Find the distance between now and the count down date
      const distance = countDownDate - now

      // Time calculations for days, hours, minutes and seconds
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setHoursUntil(hours)
      setMinutesUntil(minutes)
      setSecondsUntil(seconds)

      // If the count down is finished
      if (distance < 0) {
        resetClock()
        updateTodayAnime()
        resetToInitialState()
      }
    }, 1000)
  }

  const updateAttempt = () => {
    setCurrentAttemptNum(currentAttemptNum + 1)
  }

  // Render Guess Bars

  const renderResultBar = (barNum) => {
    if (!action[barNum]) {
      return <CurrentBar isSelected={currentAttemptNum == barNum} />
    } else {
      if (action[barNum] === 'failedguess') {
        return <FailedBar failedGuess={submittedAnime[barNum]} />
      }
      if (action[barNum] === 'skip') {
        return <SkipBar />
      }
    }
  }

  return (
    <>
      <PageSEO title={siteMetadata.title} description={siteMetadata.description}></PageSEO>
      <SectionContainer>
        <div className="flex h-full flex-col justify-between">
          {isDataReady && (
            <>
              {finishedGame ? (
                <main className="flex h-full flex-col justify-between">
                  <button aria-label="Go to today's anime guess site">
                    <a href="" className="text-left">
                      <div className="flex min-h-[9rem]">
                        <Image
                          className="rounded-l"
                          src={todayAnime.image_url}
                          alt="Today's Animerdle cover picture"
                          title="Today's Animerdle cover picture"
                          width={160}
                          height={200}
                        />
                        <div className="w-full rounded-md border-gray-700 bg-gray-300 p-5 dark:bg-gray-800">
                          <div className="flex h-full flex-col justify-between">
                            <div className="text-xl font-bold text-gray-800 dark:text-gray-200">
                              {todayAnime.title}
                            </div>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              Type: {todayAnime.type}
                            </p>
                            <p className="text-sm text-gray-600 dark:text-gray-400">
                              Status: {todayAnime.status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </button>

                  <div className="flex flex-col gap-3">
                    <span className="flex justify-center font-bold">
                      {win && "You guessed today's Animerdle!"}
                      {!win && "You didn't guess today's Animerdle."}
                    </span>
                    <div className="flex justify-center text-sm text-gray-400">
                      {win && 'Good job, you know your Anime.'}
                      {!win && 'Better luck next time!'}
                    </div>

                    <div className="flex justify-center gap-2">
                      {action.map((s, index) => {
                        let color = ''

                        if (theme.theme === 'dark') {
                          if (s === 'skip') color = 'gray'
                          if (s === 'failedguess') color = '#A93226'
                          if (s === 'correctguess') color = 'green'
                          if (s === '') color = 'white'
                        } else {
                          if (s === 'skip') color = '#212F3C'
                          if (s === 'failedguess') color = 'red'
                          if (s === 'correctguess') color = 'green'
                          if (s === '') color = '#AEB6BF'
                        }

                        return (
                          <svg
                            key={index}
                            className="h-5 w-5 "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className={`opacity-50`}
                              style={{ stroke: `${color}` }}
                              cx="12"
                              cy="12"
                              r="10"
                              strokeWidth="3"
                            ></circle>
                          </svg>
                        )
                      })}
                    </div>
                  </div>

                  <div className="mb-5 flex flex-col items-center">
                    <span className="text-sm text-gray-500">Next Animerdle in</span>
                    {hoursUntil <= 0 && minutesUntil <= 0 && secondsUntil <= 0 ? (
                      <svg
                        className="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <span className="text-xl">
                        {('0' + hoursUntil).slice(-2)}:{('0' + minutesUntil).slice(-2)}:
                        {('0' + secondsUntil).slice(-2)}
                      </span>
                    )}
                  </div>
                </main>
              ) : (
                <main className="flex h-full flex-col justify-between">
                  <div className="relative flex flex-col justify-between">
                    <div className="grid grid-cols-1 gap-2">
                      {renderResultBar(0)}
                      {renderResultBar(1)}
                      {renderResultBar(2)}
                      {renderResultBar(3)}
                      {renderResultBar(4)}
                      {renderResultBar(5)}
                    </div>
                  </div>
                </main>
              )}
              <div
                className={`${
                  opaqueExceptPlay && 'absolute top-0 left-0 h-full w-full bg-black opacity-70'
                }`}
              ></div>
              <DynamicPlayController
                onSubmit={onSubmit}
                onSkip={onSkip}
                currentAttemptNum={currentAttemptNum}
                setCurrentAttemptNum={setCurrentAttemptNum}
                updateAttempt={() => updateAttempt()}
                finishedGameStateRef={finishedGameStateRef}
                todayAnime={todayAnime}
                setOpaqueExceptPlay={setOpaqueExceptPlay}
                opaqueExceptPlay={opaqueExceptPlay}
                currentGameStats={currentGameStats}
                soundUrl={soundUrl}
              />
            </>
          )}
        </div>
      </SectionContainer>
    </>
  )
}
